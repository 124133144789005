import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>Changes to our mortgage products</h1>
    <p><strong>Following the Monetary Policy Committee announcement on Thursday 3<sup>rd</sup> February 2022, the Bank of England base rate has increased from 0.25% to 0.50%.</strong></p>
    <p>With effect from Friday 4<sup>th</sup> February 2022 the changes are as follows:</p>

    <ul><li>All tracker rates will include the increased Bank of England Base Rate.</li></ul>

    <p>There are no changes to our Residential Standard Variable Rate or Buy to Let Standard Variable Rate.</p>


    <p>There are no other changes to any of our other product ranges.</p>
    <p>An updated mortgage rate sheet will be published on Friday 4<sup>th</sup> February 2022 to reflect these changes.</p>
    <p><a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>Link to our latest rate sheet</a></p>
  </NewsArticle>
)

export default Article
